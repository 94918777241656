import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {CustomPageSection} from '../../../models/page-builder/custom-page-section.model';
import {filter} from 'rxjs/operators';
import {CustomPage} from '../../../models/page-builder/custom-page.model';


@Injectable({
  providedIn: 'root'
})
export class EditSectionService {

  private sectionIndex: number;

  private originalPage: CustomPage

  private subject = new BehaviorSubject<{
    section: CustomPageSection,
    index: number
  }>(null);

  private dirty:boolean = false;

  sectionChanged$ = this.subject.asObservable()
    .pipe(
      filter(val => !!val)
    );

  setSectionIndex(index: number) {
    this.sectionIndex = index;
  }

  updateSection(section: CustomPageSection) {

    this.checkSectionChanged(section)
    //   this.dirty = true;

    this.subject.next({
      section,
      index: this.sectionIndex
    });
  }

  isDirty() {
    return this.dirty;
  }

  pageSaved() {
    this.dirty = false;
  }

  setPageTouched() {
    this.dirty = true
  }

  checkPageChanged(page) {

    const originalPage = this.originalPage;

    console.log(page, originalPage)

    if(!this.isEqual(page, originalPage)) {

      this.setPageTouched()

    } else {

      this.pageSaved()

    }

  }

  isEqual(value1, value2) {
    // Check if both values are strictly equal
    if (value1 === value2) return true;

    // Check if either value is null or undefined
    if (value1 == null || value2 == null) return false;

    // Check if both values are arrays
    if (Array.isArray(value1) && Array.isArray(value2)) {
      if (value1.length !== value2.length) return false;
      for (let i = 0; i < value1.length; i++) {
        if (!this.isEqual(value1[i], value2[i])) return false;
      }
      return true;
    }

    // Check if both values are objects (and not arrays)
    if (typeof value1 === 'object' && typeof value2 === 'object') {
      const keys1 = Object.keys(value1);
      const keys2 = Object.keys(value2);

      // Check if they have the same number of keys
      if (keys1.length !== keys2.length) return false;

      // Check if they have the same keys
      for (const key of keys1) {
        if (!keys2.includes(key) || !this.isEqual(value1[key], value2[key])) return false;
      }
      return true;
    }

    // Check if both values are dates
    if (value1 instanceof Date && value2 instanceof Date) {
      return value1.getTime() === value2.getTime();
    }

    // Check if both values are regex
    if (value1 instanceof RegExp && value2 instanceof RegExp) {
      return value1.toString() === value2.toString();
    }

    // If none of the above conditions are met, the values are not equal
    return false;
  }

  checkSectionChanged(section) {

    const originalPageSection = this.originalPage?.sections;

    if(!this.isEqual(section, originalPageSection)) {

      this.setPageTouched()

    } else {

      this.pageSaved()

    }

  }

  setOriginalPage(page: CustomPage) {
    this.originalPage = { ...page };
  }

  reset() {
    if(this.originalPage) {
      if(this.originalPage?.sections) {
        this.originalPage.sections = null
      }
      this.originalPage = null
    }

    this.sectionIndex = null
    this.dirty = false;
    this.subject.next(null)
  }

  getOriginalPage() {
    return this.originalPage;
  }

}
